import React, { useState, useContext, Fragment } from "react";
import Link from "@material-ui/core/Link";
import AppBar from "@material-ui/core/AppBar";
import { StoreContext } from "../store";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import MenuIcon from "@material-ui/icons/Menu";
import WorkIcon from "@material-ui/icons/Work";
import StorageIcon from "@material-ui/icons/Storage";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { logout } from "./LoginPasswordless";
import PaymentIcon from "@material-ui/icons/Payment";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import "../css/RelinkDrawer.css";
import logoEMF from "../assets/logo-EMF.png";
import logoCeconte from "../assets/logo-ceconte.png";
import UpdateWarning from "./UpdateWarning";
import GroupIcon from "@material-ui/icons/Group";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import EventNote from "@material-ui/icons/EventNote";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing() * 3,
  },
  responsiveTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      overflow: "visible",
      whiteSpace: "normal",
      padding: "5px",
    },
  },
});

const DrawerItem = observer(({ path, icon, label }) => (
  <ListItem button key={path} component={RouterLink} to={path}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={label} />
  </ListItem>
));

// Update this object to use 'org' as the key
const orgLogoMapping = {
  EMF: logoEMF,
  default: logoCeconte,
};

// Update the determineLogo function
const determineLogo = (turma) => {
  return orgLogoMapping[turma.org] || orgLogoMapping.default;
};

const isEMFCourse = (turma) => {
  return turma.org === "EMF";
};

function getHeaderColorClassName(turma) {
  return isEMFCourse(turma) ? "colorheaderEMF" : "colorheaderCeconte";
}

const shouldShowMaterials = (turma) => {
  return !turma.name.includes("EM") && !turma.name.includes("FM");
};


const ResponsiveDrawer = observer((props) => {
  const store = useContext(StoreContext);
  var turmas = {};

  store.turmas.forEach((t) => {
    if (!turmas[t.course]) turmas[t.course] = [t];
    else turmas[t.course].push(t);
  });
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { classes, theme } = props;

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <div className="divIMG">
          <img
            src={determineLogo(store.ui.turma)}
            className={isEMFCourse(store.ui.turma) ? "logoEMF" : "logoCeconte"}
            alt={isEMFCourse(store.ui.turma) ? "logo-EMF" : "logo-Ceconte"}
          />
        </div>
      </div>
      <Divider />
      <List>
        <DrawerItem
          path="/"
          icon={<DashboardIcon className="colorIcon" />}
          label="Painel"
        />
        {(store.default_role === "user" ||
          store.default_role === "admin" ||
          store.default_role === "manager") &&
          store.authorized && (
            <Fragment>
              <DrawerItem
                path="/matricula/"
                icon={<AccountCircleIcon className="colorIcon" />}
                label="Meus Dados"
              />
              {!store.ui.turma.course.includes("Rodas") &&
                !store.ui.turma.course.includes("Supervisão") ? (
                <DrawerItem
                  path="/listActivities/"
                  icon={<MenuBookIcon className="colorIcon" />}
                  label="Atividades"
                />
              ) : (
                <DrawerItem
                  path="/listActivities/"
                  icon={<MenuBookIcon className="colorIcon" />}
                  label="Vídeos"
                />
              )}
              {shouldShowMaterials(store.ui.turma) && (
                <DrawerItem
                  path="/materials/"
                  icon={<WorkIcon className="colorIcon" />}
                  label="Materiais"
                />
              )}
              {store.ui.turma.org === "EMF" ? (
                <DrawerItem
                  path="/grades-antigo/"
                  icon={<AssignmentTurnedIn className="colorIcon" />}
                  label="Avaliações e Frequências"
                />
              ) : (
                ""
              )}

              {store.admin ? (
                <DrawerItem
                  path="/grades-admin/"
                  icon={<AssignmentTurnedIn className="colorIcon" />}
                  label="Avaliações"
                />
              ) : (
                <DrawerItem
                  path="/grades-student/"
                  icon={<AssignmentTurnedIn className="colorIcon" />}
                  label="Avaliações"
                />
              )}
              <DrawerItem
                path="/attendances/"
                icon={<EventNote className="colorIcon" />}
                label="Frequências"
              />
              <DrawerItem
                path="/payments"
                icon={<PaymentIcon className="colorIcon" />}
                label="Pagamentos"
              />
            </Fragment>
          )}
        {(store.admin || store.is_monitor) && (
          <>
            <DrawerItem
              path="/alunos"
              icon={<GroupIcon className="colorIcon" />}
              label="Alunos"
            />
          </>
        )}
        {store.admin && (
          <>
            <DrawerItem
              path="/viewDB"
              icon={<StorageIcon className="colorIcon" />}
              label="Banco de Dados"
            />
          </>
        )}
        {(store.admin || store.is_consultant) && (
          <>
            <DrawerItem
              path="/applicants"
              icon={<AssignmentIndIcon className="colorIcon" />}
              label="Inscrições"
            />
          </>
        )}
        {store.is_evaluator && (
          <DrawerItem
            path="/coursework/"
            icon={<LibraryBooksIcon className="colorIcon" />}
            label="Projetos e Trabalhos"
          />
        )}
      </List>
      {store.turmas.length > 1 && (
        <div className="selectClass">
          <Typography variant="overline" className="selectTurma">
            {" "}
            Selecione sua Turma:{" "}
          </Typography>
          {Object.keys(turmas)
            .sort()
            .map((course) => (
              <Accordion key={course} style={{ marginTop: "5px" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle2">{course}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  {turmas[course]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((t) => (
                      <Button
                        className="turma-button"
                        key={t.id}
                        onClick={() => {
                          store.ui.lessonPos = 0;
                          store.ui.turma = t;
                          localStorage.setItem("lastTurma", JSON.stringify(t));
                        }}
                      >
                        {t.name}
                      </Button>
                    ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      )}
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <UpdateWarning />
        <Toolbar className={getHeaderColorClassName(store.ui.turma)}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={`${classes.title} ${classes.responsiveTitle}`}
          >
            {store.ui.turma.category_name} ({store.ui.turma.name})
          </Typography>
          {store.logged_in && (
            <Fragment>
              <Typography variant="caption">{store.email}</Typography>
              <Link onClick={logout} variant="caption" color="textPrimary">
                <span className="logout">(logout)</span>
              </Link>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={props.container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
});

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
