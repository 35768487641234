/* eslint-disable no-ex-assign */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useContext } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import { Formik, Field, Form, FieldArray } from "formik";
import { Button, IconButton } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Paper from "@material-ui/core/Paper";
import functions from "./GQL";
import { StoreContext } from "../store";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const ChangeDBData = (props) => {
  // null id will make this an Insert component
  const id = props.match.params.id;
  const table = props.match.params.table;
  const params = new URLSearchParams(props.location.search);
  // eslint-disable-next-line no-unused-vars
  const store = useContext(StoreContext);
  const formatData = (values) => {
    //format for nested objects GQL
    var message = "";
    var table = values["__typename"];
    delete values["__typename"];
    if (functions[table].checkData)
      message += functions[table].checkData(values);
    if (id) {
      //update query
      delete values["id"];
      Object.keys(values).forEach((k) => {
        if (values[k] === "") {
          // insert nulls because of unique fields
          if (functions[table].defaults && functions[table].defaults[k]) {
            delete values[k];
          } else {
            values[k] = null;
          }
        } else if (values[k] != null && typeof values[k] === "object") {
          delete values[k];
        }
      });
      values = { variables: { id: id, _set: { ...values } } };
    } else {
      Object.keys(values).forEach((key) => {
        if (Array.isArray(values[key])) {
          values[key].forEach((value) => {
            formatData(value); //recursion
          });
          values[key] = { data: values[key] };
        } else {
          if (values[key] === "") {
            // insert nulls because of unique fields
            if (
              functions[table].defaults &&
              functions[table].defaults[key] != null
            ) {
              delete values[key];
            } else {
              values[key] = null;
            }
          }
        }
      });
      values = { variables: { objects: [{ ...values }] } };
    }
    if (message) {
      alert(message);
      return {};
    } else {
      return values;
    }
  };

  const clear = (child) => {
    Object.keys(child).forEach((key) => {
      if (Array.isArray(child[key])) {
        child[key].forEach((sub) => {
          clear(sub);
        });
      } else if (key === "id") {
        delete child[key];
      } else if (key === "__typename") {
        //preserves
      } else if (typeof child[key] === "object") {
        //maintain parent relationship
      } else {
        let value = params.get(key);
        child[key] = value ? value : "";
      }
    });
    return child;
  };

  const newChild = (parent, example) => {
    var child = JSON.parse(JSON.stringify(parent[0]));
    return clear(child);
  };

  const nestedField = (values, relationship, lists) => {
    //render fields
    var tabela = relationship.split(".");
    if (relationship === table) relationship = "";
    else relationship = relationship + ".";
    tabela = tabela[tabela.length - 1];
    if (tabela && tabela.includes("[")) {
      var num = tabela.split("[")[1].split("]")[0];
      num++;
    } else {
      num = "";
    }
    tabela = tabela.split("[")[0];
    if (tabela[tabela.length - 1] === "s")
      tabela = tabela.substring(0, tabela.length - 1);
    if (!functions[tabela]) tabela = "activity";
    tabela = values.__typename;
    return (
      <>
        <h2 style={{ display: "flex" }}>{tabela + " " + num}</h2>
        {Object.keys(values).map((field, key) => {
          if (field === "id") {
            if (!id) delete values[field]; //insert without id fields
          } else if (field[0] === "_") {
            //__typename, unnecessary
            //delete values[field]
          } else if (Array.isArray(values[field])) {
            // relação 1:N
            let child_table = values[field][0];
            if (child_table) child_table = child_table.__typename;
            else child_table = field.substring(0, field.length - 1);
            return (
              <>
                <FieldArray
                  name={relationship + field}
                  render={(arrayHelpers) => (
                    <div>
                      {values[field].map((value, i) => {
                        return (
                          <>
                            <Paper style={{ padding: 30, margin: 10 }}>
                              {!id && (
                                <IconButton
                                  onClick={() => {
                                    arrayHelpers.remove(i);
                                  }}
                                  style={{ float: "right" }}
                                >
                                  <HighlightOffTwoToneIcon />
                                </IconButton>
                              )}
                              {nestedField(
                                value,
                                relationship + field + "[" + i + "]",
                                lists
                              )}
                            </Paper>
                          </>
                        );
                      })}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          // eslint-disable-next-line no-unused-vars
                          let table = field.substring(0, field.length - 1);
                          if (id) {
                            props.history.push(
                              `/editDB/${child_table}?${tabela}=${values.id}`
                            );
                            window.location.reload();
                          } else {
                            arrayHelpers.push(newChild(values[field]));
                          }
                        }}
                      >
                        new {field.substring(0, field.length - 1)}
                      </Button>
                    </div>
                  )}
                />
              </>
            );
          } else if (
            values[field] !== null &&
            typeof values[field] === "object"
          ) {
            // N:1
            /*return( //furure use, showing dropdown
            <>
            <Paper elevation={3} style={{padding:30}}>
            {nestedField(values[field],field,lists)}
            </Paper>
            </>
          )*/ delete values[
              field
            ];
          } else {
            //campo de edição
            return (
              <>
                <p />
                <Field
                  type={
                    functions[tabela].fieldtypes && functions[tabela].fieldtypes[field]
                      ? functions[tabela].fieldtypes[field]
                      : "text"
                  }
                  label={field}
                  name={relationship === "" ? field : relationship + field}
                  defaultChecked={
                    functions[tabela].fieldtypes && 
                    functions[tabela].fieldtypes[field] === "checkbox" 
                      ? values[field] 
                      : undefined
                  }
                  fullWidth
                  multiline={
                    (functions[tabela].fieldtypes &&
                      functions[tabela].fieldtypes[field] === "textarea") ||
                    field === "description"
                      ? true
                      : false
                  }
                  inputProps={{ 
                    defaultChecked: functions[tabela].fieldtypes && 
                      functions[tabela].fieldtypes[field] === "checkbox" 
                        ? values[field] 
                        : undefined,
                    step: 0.01 
                  }}
                  component={TextField}
                  InputLabelProps={{ shrink: true }}
                  onClick={() => {
                    if (id && tabela !== table) {
                      props.history.push(`/editDB/${tabela}/${values.id}`);
                      window.location.reload();
                    }
                  }}
                  select={lists[field] ? true : false}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {lists[field] &&
                    [{ name: "" }].concat(lists[field]).map((obj) => (
                      <option value={obj.id} className="p5">
                        {obj.name}
                      </option>
                    ))}
                </Field>
                <p />
              </>
            );
          }
        })}
      </>
    );
  };
  //load record:
  const mutation = id ? functions[table].EDIT : functions[table].INSERT;
  const [update] = useMutation(mutation);
  let variables = id ? { variables: { id } } : {};
  var { data, error, loading, refetch } = useQuery(
    functions[table].GET,
    variables
  );
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (ChangeDBData.jsx): {error.message}</p>;
  var lists = functions[table].lists ? functions[table].lists(data) : {};
  data = data[table];
  data = JSON.parse(JSON.stringify(data)); //clone data to prevent errors
  if (!id) {
    data = newChild(data);
    //Object.keys(data).forEach(k => data[k] = "")
  } else {
    for (var i = 0; i < Object.keys(data).length; i++) {
      if (data[i].id == id) {
        data = data[i];
        i = Object.keys(data).length;
      }
    }
    //remove nested fields for edit:
    Object.keys(data).forEach((k) => {
      //if(typeof(data[k])==='object') delete data[k]
    });
  }
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <IconButton
          onClick={() => {
            props.history.goBack();
            refetch();
          }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      </div>
      <div>
        <Formik
          initialValues={data}
          onSubmit={async (values, actions) => {
            // copy object to avoid nulls warnings
            var newValues = JSON.parse(JSON.stringify(values));
            try {
              newValues = formatData(newValues);
              // eslint-disable-next-line no-unused-vars
              var res = await update(newValues);
              alert("Concluído.");
              refetch();
            } catch (err) {
              if (err.graphQLErrors[0]?.extensions?.internal)
                err =
                  "Error: " +
                  err.graphQLErrors[0]?.extensions?.internal.error.message;
              alert(err);
            }
            actions.setSubmitting(false);
          }}
          render={({ values }) => (
            <Form>
              <Paper elevation={2} style={{ padding: 30 }}>
                <FieldArray
                  name="friends"
                  render={(arrayHelpers) => (
                    <div>
                      {nestedField(values, table, lists)}
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ margin: "30px" }}
                        >
                          Salvar
                        </Button>
                      </div>
                    </div>
                  )}
                />
              </Paper>
            </Form>
          )}
        />
      </div>
    </>
  );
};
export default ChangeDBData;
