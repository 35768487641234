import React, { Fragment, useContext } from "react";
import { observer } from "mobx-react-lite";
import { store, StoreContext } from "../store";
import { useMutation, useQuery } from "react-apollo-hooks";
import { Link as RouterLink } from "react-router-dom";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Typography, Tooltip, Divider } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditOutlinedIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { History } from "history";

import {
  getAllActivities,
  ActivityInterface,
  lessonInterface,
  getAllACtivitiesInterface,
  removeActivity,
  removeLesson,
} from "../queriesAndMutations";
import LessonFeedback from "./LessonFeedback";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
  });

interface ClassesInterface {
  root: string;
  inline: string;
}

interface ActivityProps {
  activity: ActivityInterface;
  lesson: lessonInterface;
  classes: ClassesInterface;
  history: History;
  admin: Boolean;
}

interface ClassesInterface {
  [key: string]: string;
}

const Activity = ({
  classes,
  activity,
  lesson,
  history,
  admin,
}: ActivityProps) => {
  const { title, type } = activity;
  const completion = activity.activity_completions[0]
    ? activity.activity_completions[0]
    : { comment: "", completed: false, date_done: "" };
  const icon = completion.completed ? (
    <CheckBoxIcon />
  ) : (
    <CheckBoxOutlineBlankIcon />
  );
  const MyLink = (props: any) => (
    <RouterLink
      to={{ pathname: "/viewActivity", state: { activity, lesson } }}
      {...props}
    />
  );
  const [deleteActivity] = useMutation(removeActivity);
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ListItem
          button
          component={MyLink}
          alignItems="flex-start"
          disabled={!activity.available}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={type}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {title}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        {admin && (
          <div className="flex-col">
            <Tooltip title="edita">
              <IconButton
                onClick={() => {
                  history.push(`/editactivity/${activity.id}`);
                }}
                style={{
                  color: "#66BB6A",
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="remover">
              <IconButton
                onClick={async () => {
                  if (window.confirm("Excluir?")) {
                    await deleteActivity({ variables: { id: activity.id } });
                    if (store.ui.refetch) store.ui.refetch();
                  }
                }}
                style={{
                  color: "#FF0000",
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <Divider />
    </Fragment>
  );
};

interface ListActivitiesProps {
  classes?: ClassesInterface;
  history: History;
}

const ListActivities = observer(({ history, classes }: ListActivitiesProps) => {
  const store = useContext(StoreContext);
  const email = store.email;
  const turmaId = store.ui.turma.id;
  const [deleteLesson] = useMutation(removeLesson);

  const { data, error, loading, refetch } = useQuery<getAllACtivitiesInterface>(
    getAllActivities,
    {
      variables: { email, turmaId },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (ListActivities.js): {error.message}</p>;

  const lesson = data!.lesson;
  if (lesson.length <= 0) return <p>Lições ainda não carregadas...</p>;

  store.ui.refetch = refetch;

  const firstAvailableIndex =
    lesson
      .map((l, index) => ({ available: l.available, index }))
      .filter((l) => l.available || store.admin)
      .shift()?.index ?? 0;

  const lastAvailableIndex =
    lesson
      .map((l, index) => ({ available: l.available, index }))
      .filter((l) => l.available || store.admin)
      .pop()?.index ?? 0;

  if (!store.ui.lessonDirty) {
    store.ui.setLessonPos(lastAvailableIndex);
    store.ui.lessonDirty = true;
  }

  if (!lesson[store.ui.lessonPos].available && !store.admin) {
    store.ui.setLessonPos(lastAvailableIndex);
  }

  return (
    <div>
      {(lesson[store.ui.lessonPos].available || store.admin) && (
        <>
          <LessonFeedback
            lessonId={lesson[store.ui.lessonPos].id}
            key={lesson[store.ui.lessonPos].id}
            personId={store.personId}
            turmaId={store.ui.turma.id}
          />
          <Typography style={{ fontSize: 20 }}>
            {lesson[store.ui.lessonPos].disciplinaByDisciplina
              ? `Disciplina: ${lesson[store.ui.lessonPos].disciplinaByDisciplina.name}`
              : ""}
          </Typography>
          <div style={{ 
            opacity: !lesson[store.ui.lessonPos].available && store.admin ? 0.5 : 1,
            backgroundColor: !lesson[store.ui.lessonPos].available && store.admin ? '#f5f5f5' : 'transparent',
            paddingTop: '4px',
            paddingBottom: '4px',
            borderRadius: '4px'
          }}>
            <Typography variant="h5">
              {!store.ui.turma.course.includes("Rodas") &&
              !store.ui.turma.course.includes("Supervisão")
                ? "Lição " + lesson[store.ui.lessonPos].number.toString() + ": "
                : "Vídeo " + lesson[store.ui.lessonPos].number.toString() + ": "}
              {lesson[store.ui.lessonPos].title}
              {store.admin && (
                <>
                  {!lesson[store.ui.lessonPos].available && (
                    <Typography 
                      variant="caption" 
                      style={{ 
                        color: '#666',
                        marginLeft: '10px',
                        fontStyle: 'italic'
                      }}
                    >
                      (Lição indisponível para alunos)
                    </Typography>
                  )}
                  <Tooltip title="editar">
                    <IconButton
                      onClick={() => {
                        history.push(`/editDB/lesson/${lesson[store.ui.lessonPos].id}`);
                      }}
                      style={{
                        color: "#66BB6A",
                      }}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="remover">
                    <IconButton
                      onClick={async () => {
                        if (window.confirm("Excluir?")) {
                          await deleteLesson({
                            variables: { id: lesson[store.ui.lessonPos].id },
                          });
                          if (store.ui.refetch) store.ui.refetch();
                        }
                      }}
                      style={{
                        color: "#FF0000",
                      }}
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Typography>
          </div>
          <Typography variant="body1">
            com: {lesson[store.ui.lessonPos].professor}
          </Typography>
          <Tooltip title="ver lição anterior">
            <IconButton
              disabled={store.ui.lessonPos <= firstAvailableIndex}
              onClick={() =>
                store.ui.lessonPos > firstAvailableIndex
                  ? store.ui.setLessonPos(store.ui.lessonPos - 1)
                  : null
              }
            >
              <ArrowLeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="ver lição seguinte">
            <IconButton
              disabled={store.ui.lessonPos >= lastAvailableIndex}
              onClick={() =>
                store.ui.lessonPos < lastAvailableIndex
                  ? store.ui.setLessonPos(store.ui.lessonPos + 1)
                  : null
              }
            >
              <ArrowRightIcon />
            </IconButton>
          </Tooltip>
          {store.admin && (
            <Tooltip title="adicionar">
              <IconButton
                onClick={() => {
                  history.push(`/editDB/lesson?turma=${turmaId}`);
                }}
                style={{
                  color: "#66BB6A",
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          )}
          <br />
          <List className={classes!.root}>
            {lesson[store.ui.lessonPos].activities.map((activity) => (
              <Activity
                key={activity.id}
                activity={activity}
                lesson={lesson[store.ui.lessonPos]}
                classes={classes!}
                history={history}
                admin={store.admin}
              />
            ))}
            {store.admin && (
              <Tooltip title="adicionar">
                <IconButton
                  onClick={() => {
                    history.push(
                      `/editactivity?lesson=${lesson[store.ui.lessonPos].id}`
                    );
                  }}
                  style={{
                    color: "#66BB6A",
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
          </List>
        </>
      )}
      {!lesson[store.ui.lessonPos].available && !store.admin && (
        <Typography variant="body1">
          Esta lição não está disponível no momento.
        </Typography>
      )}
    </div>
  );
});

export default withStyles(styles)(ListActivities);
