/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useQuery, useMutation } from "react-apollo-hooks";
import { StoreContext } from "../store";
import { Paper, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import { Formik, Field, Form } from "formik";
import Button from "@material-ui/core/Button";
import { TextField } from "formik-material-ui";
import axios from "axios";
import gql from "graphql-tag";
import "../css/EditActivity.css";

const styles = {
  root: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 15,
    marginRight: 15,
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
};

const EditActivity = observer(({ classes, location, history, match }) => {
  const store = useContext(StoreContext);
  const id = match.params.id;
  const { data, error, loading } = useQuery(query, {
    variables: { id, turma: store.ui.turma.id },
  });
  const [update] = useMutation(mutation);
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    //get video list from Vimeo
    async function getData() {
      let { data } = await axios.get(
        `/.netlify/functions/vimeo?course=${store.ui.turma.course}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth0:id_token"),
          },
        }
      );
      setVideos(data);
    }
    getData();
  }, []);
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR: {error.message}</p>;
  const activity = { ...data.activity[0] };
  const lesson = JSON.parse(JSON.stringify(activity.lessonByLesson));
  var params = new URLSearchParams(window.location.search);
  if (!id)
    Object.keys(activity).forEach((k) => {
      let value = params.get(k);
      activity[k] = null;
      if (value) activity[k] = value;
    });
  return (
    <>
      <Formik
        initialValues={activity}
        onSubmit={async (values, actions) => {
          let object = { ...values };
          Object.keys(object).forEach((key) => {
            //remove table relationships
            if (typeof object[key] === "object") delete object[key];
          });
          if (!id) delete object.id;
          delete object.__typename;
          try {
            await update({ variables: { object } });
            alert("Concluído");
          } catch (err) {
            // eslint-disable-next-line no-ex-assign
            if (err.graphQLErrors[0]?.extensions?.internal)
              // eslint-disable-next-line no-ex-assign
              err =
                "Error: " +
                err.graphQLErrors[0]?.extensions?.internal.error.message;
            alert(JSON.stringify(err));
          }
          actions.setSubmitting(false);
        }}
        render={({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Paper elevation={2} className={classes.root}>
              {store.admin && (
                <Field
                  name="lessonByLesson.available"
                  label="Lição Disponível"
                  type="checkbox"
                  component={TextField}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ 
                    defaultChecked: values.lessonByLesson?.available,
                    onChange: async (e) => {
                      try {
                        await update({ 
                          variables: { 
                            object: {
                              lesson: values.lesson,
                              available: e.target.checked
                            }
                          }
                        });
                        alert("Status de disponibilidade atualizado");
                      } catch (err) {
                        alert("Erro ao atualizar disponibilidade: " + JSON.stringify(err));
                      }
                    }
                  }}
                />
              )}
              <Field
                name="available"
                label="Atividade Disponível"
                type="checkbox"
                component={TextField}
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ defaultChecked: values.available }}
              />
              <Field
                name="lesson"
                label="Lição"
                select
                SelectProps={{ native: true }}
                fullWidth
                component={TextField}
              >
                {[{ name: "" }].concat(lesson.turmaByTurma.lessons).map((l) => (
                  <option value={l.id}>Lição {l.number}</option>
                ))}
              </Field>
              <Field
                name="type"
                label="Tipo"
                select
                SelectProps={{ native: true }}
                component={TextField}
                fullWidth
                InputLabelProps={{ shrink: true }}
              >
                {[{ name: "" }].concat(data.activity_type).map((type) => (
                  <option value={type.name}>{type.name}</option>
                ))}
              </Field>
              <Field
                name="title"
                label="Título"
                component={TextField}
                fullWidth
              />
              <br />
              <Field
                name="description"
                label="Descrição"
                component={TextField}
                fullWidth
                multiline
              />
              <Field
                name="ui_order"
                label="Ui Order"
                type="number"
                component={TextField}
                fullWidth
              />
              <Field
                name="deadline"
                style={{ paddingTop: 20 }}
                type="date"
                label="Data limite para entregar essa atividade"
                component={TextField}
                fullWidth
              />
              <Field
                name="video_url"
                label="Video url"
                component={TextField}
                fullWidth
                InputLabelProps={{ shrink: true }}
                onFocus={() => {
                  document.getElementById("vimeo_list").style.display = "block";
                }}
                onBlur={() => {
                  setTimeout(() => {
                    document.getElementById("vimeo_list").style.display =
                      "none";
                  }, 100);
                }}
              />
              <div id="vimeo_list" className="dropdown300">
                {videos.map((video) => (
                  <li
                    className="dropdown-item"
                    onClick={(e) => {
                      setFieldValue("video_url", video.link.split(".com/")[1]);
                    }}
                  >
                    {video.name}
                  </li>
                ))}
              </div>
              {values.video_url && (
                <>
                  <Divider />
                  <br />
                  <div
                    style={{
                      ...styles.playerWrapper,
                      paddingTop: styles.playerWrapper.paddingTop,
                    }}
                  >
                    <ReactPlayer
                      className={classes.reactPlayer}
                      url={
                        values.video_url?.includes(".")
                          ? values.video_url
                          : `https://player.vimeo.com/video/${values.video_url.replace(
                              "/",
                              "?h="
                            )}`
                      }
                      width="100%"
                      height="100%"
                      controls={true}
                    />
                  </div>
                </>
              )}
              <Field
                name="slides_download_link"
                label="Link dos slides/anexo"
                fullWidth
                component={TextField}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ margin: "30px" }}
                disabled={isSubmitting}
              >
                SALVAR
              </Button>
            </Paper>
          </Form>
        )}
      />
    </>
  );
});

export default withStyles(styles)(EditActivity);

const query = gql`
  query activity($id: Int, $turma: Int) {
    activity(
      where: {
        id: { _eq: $id }
        lessonByLesson: { turma: { _eq: $turma } }
        deleted_at: { _is_null: true }
      }
    ) {
      available
      description
      id
      lesson
      slides_download_link
      deadline
      title
      type
      ui_order
      video_url
      lessonByLesson {
        id
        number
        available
        turmaByTurma {
          id
          lessons(
            where: { deleted_at: { _is_null: true } }
            order_by: { number: asc }
          ) {
            id
            number
            available
          }
        }
      }
      activity_type {
        id
        group
      }
    }
    activity_type {
      id
      name
      upload
    }
  }
`;

const mutation = gql`
  mutation MyMutation($object: activity_insert_input!) {
    insert_activity_one(
      object: $object
      on_conflict: {
        constraint: activity_pkey
        update_columns: [
          available
          description
          lesson
          slides_download_link
          deadline
          title
          type
          ui_order
          video_url
        ]
      }
    ) {
      id
    }
  }
`;
