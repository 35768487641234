import gql from "graphql-tag";

const FieldsFragment = gql`
  fragment lessonFields on lesson {
    description
    number
    professor
    start_date
    title
    turma
    disciplina
    available
  }
`;

export const GET = gql`
query getLesson($id: Int, $limit: Int) {
    lesson(where: {id: {_eq: $id}, deleted_at: {_is_null: true}}, limit: $limit,order_by: {id: desc}) {
        id
        ...lessonFields
        turmaByTurma{
          id
          name
        }
        activities(order_by:{id:asc}, where:{deleted_at: {_is_null: true}}){
          id
          available
          description
          slides_download_link
          deadline
          title
          type
          ui_order
          video_aspect_ratio
          video_url
        }
    }
    disciplina(where:{deleted_at: {_is_null: true}}){
      id
      name
    }
    turma(where:{deleted_at: {_is_null: true}}){
      id
      name
    }
    activity_type{
      id
      name
    }
}
${FieldsFragment}
`;


export const INSERT = gql`
    mutation newLesson($objects: [lesson_insert_input!]!) {
        insert_lesson(objects: $objects) {
        affected_rows
        }
    }
  ${FieldsFragment}
`

export const EDIT = gql`
  mutation updateLesson($_set: lesson_set_input={}, $id: Int){
    update_lesson(where: {id: {_eq: $id}}, _set: $_set) {
      affected_rows
    }
  }
`
export const fieldtypes = { //mudar tipos dos campos
  number: "number",
  start_date: "date",
  lesson: "number",
  available: "checkbox"
}

export const defaults = {
  available: true
}

export function flatten(data){
  data.forEach(d=>{
    Object.keys(d).forEach(key=>{
      if(Array.isArray(d[key]))
        delete d[key]
    })
    if(d.turmaByTurma) d.nome_turma = d.turmaByTurma.name
    delete d.turmaByTurma
  })    
  return data
}

export function lists(data){
  data.activity_type.forEach(activity_type=>{
    activity_type.id = activity_type.name
  })
  data.type = data.activity_type
  return data
}