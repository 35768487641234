import React, { useContext } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../store";
import { Button } from "@material-ui/core";
import gql from "graphql-tag";
import SavedSnack from "./SavedSnack";
import "../css/AddAttendances.css";

const query = gql`
  query getStudentAttendances($turma: Int, $email: String!) {
    lesson(
      where: {
        turma: { _eq: $turma }
        deleted_at: { _is_null: true }
        number: { _gte: 0.1, _lt: 99 }
      }
      order_by: { number: asc }
    ) {
      id
      number
      start_date
      title
      live_attendances(where: { personByPerson: { email: { _eq: $email } } }) {
        id
      }
    }
    live_schedule(
      where: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
    ) {
      day
    }
    person(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

const mutation = gql`
  mutation upsert($objects: [live_attendance_insert_input!]!) {
    insert_live_attendance(objects: $objects) {
      affected_rows
    }
  }
`;

const StudentAttendance = observer(() => {
  const store = useContext(StoreContext);
  const [snack, setSnack] = React.useState({ msg: "", open: false });

  const showMsg = (msg) => {
    setSnack({ msg: msg, open: true });
  };

  const [update] = useMutation(mutation);

  const turma = store.ui?.turma?.id;
  const email = store.email;

  if (!turma || !email) {
    return <p>Carregando informações...</p>;
  }

  const { data, error, loading } = useQuery(query, {
    variables: { turma: turma, email: email },
  });

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {error.message}</p>;
  if (!data || !data.lesson) return <p>Nenhum dado encontrado</p>;

  data.lesson.forEach((lesson) => {
    var dates = [];
    if (lesson.title.toUpperCase().includes("WORKSHOP")) {
      let workshopDate = new Date(lesson.start_date);
      lesson.actualDates = [workshopDate];
      dates.push(
        workshopDate.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        })
      );
    } else {
      let baseDate = new Date(lesson.start_date);

      let firstClass = new Date(baseDate);
      firstClass.setDate(firstClass.getDate() + 6);

      let secondClass = new Date(baseDate);
      secondClass.setDate(secondClass.getDate() + 7);

      lesson.actualDates = [firstClass, secondClass];

      dates.push(
        firstClass.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        })
      );
      dates.push(
        secondClass.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        })
      );
    }
    lesson.dates = dates.join(" ou ");
  });

  const handlePresenca = async (lessonId) => {
    const lessonObj = data.lesson.find((l) => l.id === lessonId);
    if (lessonObj?.live_attendances.length) {
      showMsg("Presença já registrada para esta lição");
      return;
    }

    try {
      await update({
        variables: {
          objects: [
            {
              person: data.person[0]?.id,
              lesson: lessonId,
              date: lessonObj.start_date,
            },
          ],
        },
      });
      showMsg("Presença registrada com sucesso!");
      window.location.reload();
    } catch (e) {
      showMsg(e.message);
    }
  };

  const isLessonAvailable = (lesson) => {
    if (lesson.live_attendances.length) return false;
    if (!lesson.actualDates) return false;

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return lesson.actualDates.some((lessonDate) => {
      const date = new Date(lessonDate);
      date.setHours(0, 0, 0, 0);
      return date <= now;
    });
  };

  const shouldShowLesson = (lesson) => {
    if (!lesson.actualDates || !lesson.actualDates.length) return false;
    const now = new Date();
    const firstClassDate = new Date(lesson.actualDates[0]);
    now.setHours(0, 0, 0, 0);
    firstClassDate.setHours(0, 0, 0, 0);
    return firstClassDate <= now;
  };

  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
        Registre sua presença nas aulas
      </h2>
      <p style={{ textAlign: "center", marginBottom: "20px" }}>
        Clique no botão de cada lição para registrar sua presença.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxWidth: "400px",
          margin: "0 auto",
        }}
      >
        {data.lesson
          .sort((a, b) => a.number - b.number)
          .filter(shouldShowLesson)
          .map((lesson) => (
            <Button
              key={lesson.id}
              variant="contained"
              color={lesson.live_attendances.length ? "default" : "primary"}
              onClick={() => handlePresenca(lesson.id)}
              disabled={!isLessonAvailable(lesson)}
              style={{
                justifyContent: "flex-start",
                padding: "15px",
                width: "100%",
              }}
            >
              Lição {lesson.number} - {lesson.dates}
              {lesson.live_attendances.length
                ? " (Presença registrada)"
                : !isLessonAvailable(lesson)
                ? " (Disponível apenas no dia da aula)"
                : ""}
            </Button>
          ))}
        {data.lesson.filter(shouldShowLesson).length === 0 && (
          <div
            style={{
              textAlign: "center",
              padding: "20px",
              backgroundColor: "#f5f5f5",
              borderRadius: "4px",
              color: "#666",
            }}
          >
            Nenhuma lição disponível no momento. As lições serão exibidas nas
            datas das aulas ao vivo.
          </div>
        )}
      </div>

      <SavedSnack
        open={snack.open}
        msg={snack.msg}
        setOpen={(o) => setSnack({ ...snack, open: o })}
      />
    </>
  );
});

export default StudentAttendance;
